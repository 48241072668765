import { defineStore } from 'pinia';
import axios from 'axios';


export const languageStore = defineStore('languageStore', {
  state: () => ({
    currentLanguage: {}, // 存放使用者選用的語系
    enabledLangOptions: [], // 顯示在navbar的選項
    allLangOptions: [], // 語系所有選項含未啟用
  }),
  getters: {},
  actions: {
    // 設定語系
    setLanguage(lang) {
      const vm = this;
      vm.currentLanguage = lang;
      localStorage.setItem('i18n-lang', JSON.stringify(lang));
      // this.$i18n.locale = lang.LocalizationTypeCode;
    },

    // 取得啟用語系
    getEnabledLanguageList() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Menu/LocalizationType`;

      const params = {
        isEnabled: true,
      };

      axios
        .get(api, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            vm.enabledLangOptions = response.data.Data.map((item) => ({
              LocalizationTypeCode: item.LocalizationTypeCode,
              LocalizationTypeName: item.LocalizationTypeName,
            }));
          }
        })
        .catch((error) => {
          this.$notify({
            title: '語系取得失敗',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 3500,
          });
        });
    },

    // 取得所有語系
    getAllLanguageList() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Menu/LocalizationType`;

      axios
        .get(api)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: '錯誤',
              message: response.data.ErrorMessage,
              type: 'error',
              duration: 0,
            });
          } else {
            vm.allLangOptions = response.data.Data;
          }
        })
        .catch((error) => {
          this.$notify({
            title: '語系取得失敗',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 3500,
          });
        });
    },

    // 偵測預設語系 在main.js執行
    checkDeaultLanguage() {
      const language = localStorage.getItem('i18n-lang');

      if (language && language.indexOf('LocalizationTypeCode') !== -1) {
        this.currentLanguage = JSON.parse(localStorage.getItem('i18n-lang'));
      } else {
        localStorage.removeItem('i18n-lang');
        this.currentLanguage = {
          LocalizationTypeCode: 'zh-TW',
          LocalizationTypeName: '繁體中文',
        };
      }

      return this.currentLanguage;
    },
  },
});
