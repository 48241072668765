import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/Login/Login.vue'),
  },
  {
    path: '/',
    component: () => import('@/components/Layout/Layout.vue'),
    children: [
      // 暫時首頁
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/components/Home/Home.vue'),
      },
      // 帳號管理
      {
        path: 'user',
        name: 'User',
        component: () => import('@/components/User/User.vue'),
      },
      // 角色管理
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/components/Role/Role.vue'),
      },
      // 選單管理
      {
        path: 'menu',
        name: 'Menu',
        component: () => import('@/components/Menu/Menu.vue'),
      },
      // 參數設定
      {
        path: 'parameter',
        name: 'parameter',
        component: () => import('@/components/Parameter/Parameter.vue'),
      },
      // 排程管理
      {
        path: 'schedule',
        name: 'Schedule',
        component: () => import('@/components/Schedule/Schedule.vue'),
      },
      // AD管理
      {
        path: 'activedirectory',
        name: 'ActiveDirectory',
        component: () => import('@/components/ActiveDirectory/ActiveDirectory.vue'),
      },
      // 題目維護
      {
        path: 'qustionMaintenance',
        name: 'QustionMaintenance',
        component: () => import('@/components/QustionMaintenance/QustionMaintenance.vue'),
      },
      // 測驗發布
      {
        path: 'release',
        name: 'Release',
        component: () => import('@/components/Release/Release.vue'),
      },
      //流水號查詢
      {
        path: 'serialNumber',
        name: 'SerialNumber',
        component: () => import('@/components/SerialNumber/SerialNumber.vue'),
      },
      // 外派地維護
      {
        path: 'place',
        name: 'Place',
        component: () => import('@/components/Release/Place.vue'),
      },
      // 員工資料維護
      {
        path: 'personelSys',
        name: 'PersonelSys',
        component: () => import('@/components/PersonelSys/PersonelSys.vue'),
      },
      // 部門業務性質屬性維護
      {
        path: 'nature',
        name: 'Nature',
        component: () => import('@/components/Nature/Nature.vue'),
      },
      // 測驗結果
      {
        path: 'result',
        name: 'Result',
        component: () => import('@/components/Result/Result.vue'),
      },
      // 個人測驗結果
      {
        path: 'personalResult',
        name: 'PersonalResult',
        component: () => import('@/components/Result/PersonalResult.vue'),
      },
      // 人格特質模板維護
      {
        path: 'pattern',
        name: 'Pattern',
        component: () => import('@/components/Pattern/Pattern.vue'),
      },
      // 操作手冊下載
      {
        path: 'operationBookDownload',
        name: 'OperationBookDownload',
        component: () => import('@/components/OperationBook/OperationBookDownload.vue'),
      },
    ],
  },
  // 問卷
  {
    path: '/Question',
    name: 'Question',
    component: () => import('@/views/Question/Question.vue'),
  },
  // 問卷完成頁
  {
    path: '/Complete',
    name: 'Complete',
    component: () => import('@/views/Question/Complete.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
