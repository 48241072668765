/* eslint-disable no-undef */
import Vue from 'vue';


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
Vue.use(VueQuillEditor, /* { default global options } */);

import dayjs from 'dayjs';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import 'element-ui/lib/theme-chalk/index.css';
import VueCookies from 'vue-cookies';
import pinia from '@/stores/store';
import VueI18n from 'vue-i18n'; // 引入 Vue I18n

import DatePicker from 'vue2-datepicker'; // vue2-datepicker
import 'vue2-datepicker/index.css'; // vue2-datepicker
import jquery from 'jquery';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueBootstrap4Table from 'vue-bootstrap4-table';
import {
  ValidationProvider,
  extend,
  // configure,
  localize,
  ValidationObserver,
} from 'vee-validate';
import {
  required,
  email,
  alpha,
  // eslint-disable-next-line camelcase
  alpha_num,
  min,
  max,
  // eslint-disable-next-line camelcase
  min_value,
  // eslint-disable-next-line camelcase
  is_not,
  is,
  ext,
  confirmed,
  integer,
  excluded,
  regex,
} from 'vee-validate/dist/rules';
import TW from 'vee-validate/dist/locale/zh_TW.json';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { languageStore } from '@/stores/languageStore';
import zh from '../public/i18n/zh.json'; // 存放中文語系檔

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

import store from './store';

import 'bootstrap';

// import api from './interceptors/axiosConfig--Notification.js';

import App from './App.vue';
import router from './router';

const piniaLanguage = languageStore(pinia);

Vue.component('Treeselect', Treeselect);

Vue.component('VueBootstrap4Table', VueBootstrap4Table); // 存放英文語系檔

Vue.use(VueI18n);

// //////////////// 多國語系 ///////////////////////////////////////
const language = piniaLanguage.checkDeaultLanguage();

const i18n = new VueI18n({
  locale: language.LocalizationTypeCode,
  messages: {
    'zh-TW': zh,
  },
});

//VueApexCharts
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// 預設使用的語系
// let localeLang = 'zh-TW';

// 檢查 sessionStorage 是否已有保存使用者選用的語系資訊
// if (sessionStorage.getItem('footmark-lang')) {
//   localeLang = sessionStorage.getItem('footmark-lang');
//   store.commit('setLang', localeLang);
// } else {
//   sessionStorage.setItem('footmark-lang', 'zh-TW');
//   store.commit('setLang', localeLang);
// }

// const i18n = new VueI18n({
//   locale: localeLang,
//   messages: {
//     'zh-TW': zhTW,
//     'en-US': en,
//   },
// });

Vue.component('DatePicker', DatePicker);

// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// 驗證規則
extend('excluded', excluded);
extend('regex', {
  ...regex,
  message: '密碼須包含大小寫',
  params: ['pattern'],
});
extend('confirmed', {
  ...confirmed,
  message: TW.messages.confirmed,
  params: ['target'],
});
extend('integer', integer);

extend('cellPhone', {
  validate: value => value.length === 10 && /^09\d{2}-?\d{3}-?\d{3}$/.test(value),
  message: '手機須為有效格式',
});

extend('notBeforeEducationFrom', {
  params: ['min'],
  // eslint-disable-next-line no-shadow
  validate: (value, { min }) => value > min,
  message: '結束日期不得小於起始日期',
});
extend('notBeforeExperienceForm', {
  params: ['min'],
  // eslint-disable-next-line no-shadow
  validate: (value, { min }) => value > min,
  message: '不得小於 任職期間 (起)',
});
extend('password_length', {
  validate: (value, args) => value.length >= args.length,
  params: ['length'],
  message: '{_field_} 長度不符合',
});

extend('thansize', {
  validate: (value, { thanValue }) => value >= thanValue,
  message: '{_field_} 須大於 {big}',
  params: ['thanValue', 'big'],
});
extend('ext', {
  ...ext,
  message: '檔案需為 .{_field_} 檔',
});
extend('positive', (value) => {
  if (value.length === 0) {
    return true;
  }
  return 'This field must be a positive number';
});
extend('my_not', {
  validate: (value, { now }) => value !== now,
  message: '欄位內容不得與原本相同',
  params: ['now'],
});
extend('is', {
  ...is,
  message: '請勾選同意書',
  params: ['value'],
});
extend('is_not', {
  // eslint-disable-next-line camelcase
  ...is_not,
  message: '欄位內容不得與原本相同',
  params: ['value'],
});
extend('required', required);
extend('email', email);
extend('alpha', alpha);
extend('alpha_num', alpha_num);
extend('min', {
  ...min,
  message: '{_field_}需介於 6~16 個字元之間',
  params: ['length'],
});
// eslint-disable-next-line no-undef
extend('min_value', min_value);
extend('minPW', {
  ...min,
  message: '密碼至少為 8 碼',
  params: ['length'],
});
extend('regexPW', {
  validate: (value) => /(?=.*[a-z])(?=.*[A-Z])/.test(value),
  message: '密碼須包含大小寫',
});
extend('max', max);
localize('zh_TW', TW);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// Vee-Validate

Vue.config.productionTip = false;

axios.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${VueCookies.get('token')}`;
  // eslint-disable-next-line no-param-reassign
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  const timestamp = new Date().getTime();

  // eslint-disable-next-line no-param-reassign
  config.headers.common.timestamp = timestamp;
  return config;
});

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (config) => {
    VueCookies.set('token', config.headers.authorization);
    return config;
  },
  (error) => {
    // console.log("error", error);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // if (localStorage.getItem("place") === "/admin") {
          //   router.push({ name: "AdminLogin" });
          // } else {
          //   router.push({ name: "FrontLogin" });
          // }
          router.push({ name: 'Login' });
          store.dispatch('updateLoading', false);
          store.dispatch('alertMessageModule/updateMessage', {
            message: '登入逾時，請重新登入',
            status: 'danger',
          });
          break;
        //   case 404:
        //     console.log("你要找的頁面不存在");
        //     break;
        //   case 415:
        //     router.back(); // 目前只有搜尋結果按重整後會顯示 415。
        //     break;
        case 500:
          console.log('程式發生問題');
          break;
        default:
          // console.log(error.message);
          store.dispatch('updateLoading', false);
          break;
      }
    }
    return Promise.reject(error);
  },
);

Vue.use(ElementUI, { locale });
Vue.prototype.$notify = ElementUI.Notification; // 全預註冊這個元件，可避免重複 import 常用元件

// eslint-disable-next-line func-names
Vue.prototype.openLoading = function () {
  const loading = this.$loading({ // 宣告一個loading物件
    lock: true, // 是否鎖屏
    // text: 'Loading', // 載入影片的文字
    spinner: 'el-icon-loading', // 引入的loading圖示
    background: 'rgba(0, 0, 0, 0.5)', // 背景顏色
    fullscreen: true,
    customClass: 'loadingclass', // **遮罩層新增類名，如果需要修改loading的樣式**
  });
  return loading;
};

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VueI18n);

window.$ = jquery;

Vue.prototype.dayjs = dayjs;

new Vue({
  i18n,
  router,
  store,
  pinia,
  render: (h) => h(App),
}).$mount('#app');

Vue.filter('dateFormat', (value, format = 'YYYY-MM-DD') => {
  if (!value) {
    return '-';
  }
  return dayjs(value).format(format);
});


Vue.filter('dateTimeFormat',function(value,format="YYYY-MM-DD HH:mm"){
  if (!value) {
    return '-';
  }
  return dayjs(value).format(format);
})
