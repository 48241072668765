import axios from 'axios';

export default {
  // state 屬於模組區域變數
  // actions, mutations, gatters 是屬於全域變數
  namespaced: true, // actions, mutations, gatters 是屬於區域變數
  state: {
    persons: [],
    post: [],
    compName: [],
    deptName: [],
    deptCode: [],
    r2Id: [],
    titleName: [],
    options: [],
  },
  actions: {
    getPerson(context) {
      // 原本的const url = `${window.BaseUrl.api}/personelsys/range`;
      const url = `${window.BaseUrl.api}/Org/search`;

      const obj = {};

      // context.commit('LOADING', true, {root: true});
      axios.post(url, obj).then((response) => {
        // console.log('PERSON', response.data)
        context.commit('PERSON', response.data.Data);
        // context.commit('LOADING', false, {root: true});
      });
    },
    getSetting(context) {
      // 原本的const url = `${window.BaseUrl.api}/commonconditions/personelrange`;
      const url = `${window.BaseUrl.api}/Org/personelrange`;

      // context.commit('LOADING', true, {root: true});
      axios.get(url).then((response) => {
        // console.log(response.data)

        const sortPost = response.data.Data.Post.map((item) => ({
          id: item,
          label: `${item}`,
          customLabel: `${item}`,
        }));
        context.commit('POST', sortPost);
        // context.commit('POST', response.data.Post)

        const sortCompName = response.data.Data.CompName.map((item) => ({
          id: item,
          label: `${item}`,
          customLabel: `${item}`,
        }));
        context.commit('COMPNAME', sortCompName);
        // context.commit('COMPNAME', response.data.CompName)

        const sortDeptSName = response.data.Data.DeptSName.map((item) => ({
          id: item,
          label: `${item}`,
          customLabel: `${item}`,
        }));
        context.commit('DEPTNAME', sortDeptSName);
        // context.commit('DEPTNAME', response.data.DeptSName)

        //用來篩選面試人員可以選的部門用
        const sortDeptCode = response.data.Data.Departments.map((item) => ({
          id: item.Name,
          label: item.Name,
          customLabel: item.Name,
          code: item.Code,
        }));
        context.commit('DEPTCODE', sortDeptCode);

        const sortR2ID = response.data.Data.BTEXT.map((item) => ({
          id: item,
          label: `${item}`,
          customLabel: `${item}`,
        }));
        context.commit('R2ID', sortR2ID);
        // context.commit('R2ID', response.data.BTEXT)

        const sortTitleName = response.data.Data.TitleName.map((item) => ({
          id: item,
          label: `${item}`,
          customLabel: `${item}`,
        }));
        context.commit('TITLENAME', sortTitleName);
        // context.commit('TITLENAME', response.data.TitleName)

        // context.commit('LOADING', false, {root: true});
      });
    },
    getOption(context) {
      // const url = `${window.BaseUrl.api}/commonconditions/personel`;
      const url = `${window.BaseUrl.api}/Org/SaveSearchConditions`;

      const obj = {
        compId: localStorage.CompId,
        deptId: localStorage.DeptId,
        empNo: localStorage.EmpNo,
      };

      // context.commit('LOADING', true, {root: true});
      axios.get(url, { params: obj }).then((response) => {
        // console.log('option', response.data)

        context.commit('OPTIONS', response.data.Data);

        // context.commit('LOADING', false, {root: true});
      });
    },
  },
  mutations: {
    PERSON(state, payload) {
      state.persons = payload;
    },
    POST(state, payload) {
      state.post = payload;
    },
    COMPNAME(state, payload) {
      state.compName = payload;
    },
    DEPTNAME(state, payload) {
      state.deptName = payload;
    },
    DEPTCODE(state, payload) {
      state.deptCode = payload;
    },
    R2ID(state, payload) {
      state.r2Id = payload;
    },
    TITLENAME(state, payload) {
      state.titleName = payload;
    },
    OPTIONS(state, payload) {
      state.options = payload;
    },
  },
  getters: {
    persons: (state) => state.persons,
    post: (state) => state.post,
    compName: (state) => state.compName,
    deptName: (state) => state.deptName,
    deptCode: (state) => state.deptCode,
    r2Id: (state) => state.r2Id,
    titleName: (state) => state.titleName,
    options: (state) => state.options,
  },

};
