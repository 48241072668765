<template>
  <div id="app">
         <Alert />
    <router-view/>
  </div>
</template>

<script>
import Alert from './components/AlertMessage.vue';

export default {
  name: 'app',
  components: {
    Alert,
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import "./assets/scss/BackEndLayout.scss";

.filter-block .filter-field {
    z-index: auto !important;
}
.vue-treeselect__control {
    width: auto !important;
    min-width: 150px;
}
</style>
