import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { Notification } from 'element-ui';
import alertMessageModule from './alertMessage';
import personModule from './person';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: {},
    pagePath: '', // 路由網址
    pageName: '', // 路由名稱
    isMini: false,
    highCheckData: [],
    token: '',
    editMenu: false, // 選單管理變更
    register: {},
    registerType: '',
    inRegister: false,
    lang: null, // 存放使用者選用的語系
    LetterCategory: localStorage.getItem('LetterCategory'), // 信件範本
    LetterCategoryName: localStorage.getItem('LetterCategoryName'), // 信件範本名稱
    LetterEditGuid: localStorage.getItem('LetterEditGuid'), // 信件範本編輯的那一筆Guid
    editResume: false, // 是否填寫過履歷
    menuInfo: [], // 選單的內容
    isLoading: false,
    editPersonnelIndex: 0, //  防止 新增 相關能力、考績、語言、證照、家庭成員 切換還暫存在畫面
    langOptions: [], //  語系列表
  },
  mutations: {
    LOADING(state, payload) {
      state.isLoading = payload;
    },
    EDITORDATA(state, n) {
      state.consentText = n;
    },
    EDITMAIL(state, n) {
      state.mail = n;
    },
    LOGINTEST(state, payload) {
      state.account = payload;
    },
    PAGEPATH(state, pagepath) {
      state.pagePath = pagepath;
    },
    PAGENAME(state, pagename) {
      state.pageName = pagename;
    },
    SIDEBARMINI(state, payload) {
      state.isMini = payload;
    },
    HIGHCHECK(state, payload) {
      state.highCheckData = payload;
    },
    TOKEN(state, payload) {
      state.token = payload;
    },
    // 選單管理
    EDITMENU(state, payload) {
      state.editMenu = payload;
    },
    // 選單內容
    MENUINFO(state, menu) {
      state.menuInfo = menu;
    },
    // 名冊管理或搜尋點擊的名冊的資訊
    REGISTER(state, register) {
      state.register = { ...register };
    },
    // 從卡片還是條列式點新增人員的
    REGISTERTYPE(state, payload) {
      state.registerType = payload;
    },
    INREGISTER(state, payload) {
      state.inRegister = payload;
    },
    // 切換語系設定
    setLang(state, payload) {
      state.lang = payload;
    },

    // 信件範本種類
    Letter(state, payload) {
      localStorage.setItem('LetterCategory', payload);
      state.LetterCategory = localStorage.getItem('LetterCategory');
    },
    // 信件範本種類名稱
    LetterName(state, payload) {
      localStorage.setItem('LetterCategoryName', payload);
      state.LetterCategoryName = localStorage.getItem('LetterCategoryName');
    },
    // 編輯的那一筆guid
    LetterGuid(state, payload) {
      localStorage.setItem('LetterEditGuid', payload);
      state.LetterEditGuid = localStorage.getItem('LetterEditGuid');
    },

    EDITRESUME(state, payload) {
      state.editResume = payload;
    },

    EDITPERSONNELINDEX(state) {
      // eslint-disable-next-line no-plusplus
      state.editPersonnelIndex++;
    },

    LANGOPTIONS(state, payload) {
      state.langOptions = payload;
    },

  },

  actions: {
    updateLoading(context, payload) {
      context.commit('LOADING', payload);
    },
    logintest({ commit }, payload) {
      commit('LOGINTEST', payload);
    },
    highcheck({ commit }, payload) {
      commit('HIGHCHECK', payload);
    },
    token({ commit }, token) {
      commit('TOKEN', token);
    },
    getLocalizationList({ commit }) { // 取得語系
      const api = `${process.env.VUE_APP_APIPATH}/BasicInformation/GetLocalizationType`;

      const params = {
        isEnabled: true,
      };

      axios
        .get(api, { params })
        .then((response) => {
          if (response.status === 200) {
            const list = response.data.map((item) => ({
              Guid: item.LocalizationTypeGuid,
              Code: item.LocalizationTypeCode,
              Name: item.LocalizationTypeName,
            }));

            commit('LANGOPTIONS', list);
          }
        })
        .catch((error) => {
          Notification({
            title: '語系取得失敗',
            message: error.response.data,
            type: 'error',
            duration: 3500,
          });
        });
    },
  },
  getters: {
    editMenu: (state) => state.editMenu, // 選單管理變更
    lang: (state) => state.lang, // 多國語系
    editResume: (state) => state.editResume, // 是否填寫過履歷
    editPersonnelIndex: (state) => state.editPersonnelIndex,
    langOptions: (state) => state.langOptions,
  },
  modules: {
    alertMessageModule,
    personModule,
  },
});
